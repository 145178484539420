@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Source Han Sans';
  src: url('https://cdn.sdjz.wiki/font/Sinter-Normal.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFMono-Regular';
  src: url('https://cdn.sdjz.wiki/font/JetBrainsMono-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('https://cdn.sdjz.wiki/font/JetBrainsMono-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}


p {
  letter-spacing: 0.02em !important;
}

:root {
  letter-spacing: 0.02em !important;

  --font-sans:'SF Pro SC','Source Han Sans','SF Pro Text','SF Pro Icons','PingFang SC','Helvetica Neue','Helvetica','Arial',sans-serif !important;
  --font-geist-sans: 'Source Han Sans', 'SF Pro SC','SF Pro Text','SF Pro Icons','PingFang SC','Helvetica Neue','Helvetica','Arial',sans-serif !important;
  --font-mono: 'SFMono-Regular', monospace !important;
  --font-geist-mono: 'JetBrains Mono', monospace !important;
}



@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 5% 64.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 0%;
    --foreground: 0 0% 98%;
    --card: 0 0% 0%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 0%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute inline-flex h-9 w-9 items-center justify-center rounded-full border-4 border-background bg-muted text-center -indent-px font-mono text-base font-medium;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

.lab-bg {
  --mask-offset: 100px;
  -webkit-mask: linear-gradient(to bottom,transparent,#fff var(--mask-offset) calc(100% - var(--mask-offset)),transparent),linear-gradient(to right,transparent,#fff var(--mask-offset) calc(100% - var(--mask-offset)),transparent);
  mask: linear-gradient(to bottom,transparent,#fff var(--mask-offset) calc(100% - var(--mask-offset)),transparent),linear-gradient(to right,transparent,#fff var(--mask-offset) calc(100% - var(--mask-offset)),transparent);
  /* -webkit-mask: radial-gradient(circle at 50% 50%,transparent 0,#fff calc(100% - var(--mask-offset)),transparent 100%); */
  -webkit-mask-composite: source-in,xor;
  mask-composite: intersect
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari 和 Opera */
}


.animate-left {
  animation-name: left;
}

.animate-right {
  animation-name: right;
}


.tofu-container {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}

.tofu-content {
  max-width: 1200px;
  width: 100%;
}

@media (min-width: 1536px) {
  .tofu-container {
    padding: 0 6rem;
  }
  .tofu-content {
    max-width: 1500px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .tofu-container {
    padding: 0 1rem;
  }
}

.tofu-container {
  transition: transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
  will-change: transform, opacity, filter;
  transform-style: preserve-3d;
  perspective: 100px;
}

.home-tofu {
  transform: translateY(2.6875rem) scale(0.96) ;
  transition: transform 0.7s ease-in-out 0.1s, filter 0.6s ease-in-out 0.2s;
  filter: blur(50px) saturate(2);
}

.tofu-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.8s cubic-bezier(0.33, 1, 0.68, 1);
  will-change: opacity;
}

.home-tofu::before {
  opacity: 1;
  pointer-events: auto;
}

.tofu-container.animate {
  transform: translateY(0) scale(1);
  filter: none;
  opacity: 1;
  transition-duration: 0.8s, 0.3s;
  transition-property: transform, filter, opacity;
  transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}


